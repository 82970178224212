import { useQuery } from "@tanstack/react-query";
import {  useRecoilValue, useSetRecoilState } from "recoil";
import { app_menus,  country, iam, language, last_url, name } from "./AtomDefined";
import { fetchExer_Quiz } from "../QueryFeatcher";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Question_Skeleton from "./Questions_skeleton";
import Category_Wise from "./Category_Wise";
import SeeQuiz from "./SeeQuiz";
function Exer_Quiz_Hub()
{
    const mylocation = useLocation();
    const login = useRecoilValue(name);
    const mylanguage = useRecoilValue(language);
    const whoiam = useRecoilValue(iam);
    const mycountry = useRecoilValue(country);
    const mymenus = useRecoilValue(app_menus);
    const [isurl,seturl] = useState('');
    const navigate = useNavigate();
    const myform = new FormData();
    const urlArray = mylocation.pathname.split('/').filter((part) => part !== '');
    const queryParams = new URLSearchParams(mylocation.search);
    const cat = queryParams.get("cat"); 
    const title = queryParams.get("title");
    const setlurl = useSetRecoilState(last_url);
    myform.append('fetchcategory',urlArray[3]);
    myform.append('myrole', whoiam);
  const screenheight = window.innerHeight;
    useEffect(() => {
      seturl(urlArray[3]);
      myform.set('fetchcategory',urlArray[3]);
      myform.set('fetchcategory',whoiam);
    }, [urlArray[3],whoiam])
    useEffect(() => {
      if(login==='')
      {
          setlurl(mylocation);
      }
      else
      {
        setlurl('');
      }  
    }, [login])
    
    
    const { status, data,isLoading } = useQuery({
        queryKey: ["query_quiz", isurl, mylanguage,whoiam],
        staleTime: Infinity,cacheTime:Infinity,
        queryFn: () => fetchExer_Quiz("query_quiz", myform),
        enabled: isurl !== '' && !cat,
      });
      
    return(
        title?<SeeQuiz/>:
        !cat?
        <Container style={{minHeight: screenheight-175}}>
            <Row>
                <Col sm={{span:'8',offset:'2'}}>
                    <h5 style={{margin:'10px 5px 25px 5px'}}> {urlArray[3]==='exercise'?mymenus.exercise:mymenus.quiz}</h5>
                    <div style={{boxShadow : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                            {isLoading? <Question_Skeleton/>:

                        <>
                        <Row>
                        {data?.map((item,index) => (
                            
                            <Col  xs={6} 
                             key={index} style={{textAlign:'center'}}>
                                <div onClick={() => {
  if (login) {
    navigate('/' + mylanguage + '/' + mycountry + '/' + whoiam + '/' + urlArray[3] + '/?cat=' + item[`category_${mylanguage}`]);
  } else {
    alert('Please login to go further');
    navigate('/' + mylanguage + '/' + mycountry + '/' + whoiam + '/login');
  }
}}
 style={{margin:'10px', cursor:'pointer',  color:'black', backgroundColor:'#c9c05f' , padding:'10px',border:'1px solid black',borderRadius:'50px'}}>{item[`category_${mylanguage}`]} { item.answered?'('+item.answered+'/'+item.total_count+')':'('+item.total_count+')'}</div>
                            </Col>
        ))}
        </Row>
                        </>
                                                    
                        }
                    </div>

                </Col>
            </Row>
        </Container>
        :<Category_Wise type = {urlArray[3]}/>
    )
}


export default Exer_Quiz_Hub