import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { app_menus, name, user_img } from './AtomDefined';
import { Modal, Button } from 'react-bootstrap';
import Image_Upload from './Image_Upload';


const MyCircle = () => {
    const myname = useRecoilValue(name);
    const [modalShow, setModalShow] = useState(false);
    const myimage = useRecoilValue(user_img);
    const mymenus = useRecoilValue(app_menus);
    function handlemodal()
    {
        setModalShow(true);
    }
    const handleClose = () => setModalShow(false);
  return (
    <div>
    <Modal
      show = {modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {mymenus.u_p_p}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
              }}>
               
                    <Image_Upload closeModal = {handleClose}/>
       
      </Modal.Body>
    </Modal>
    <div className="circle">
      
      {myimage!==''?<> <div className="circle-content"> <img src={`https://bujho-quiz.com/server_files/upload/${myimage}`} style={{borderRadius:'50%'}} height={'80px'} width={'80px'}/>
       <span onClick={()=>handlemodal()} className="circle-overlay">{mymenus.change_p}</span> </div> </>
      :
   <div className="circle-content">   <span className="circle-character">     {myname !== "" ? myname.charAt(0).toUpperCase() : "G"}</span>
   {myname!==''? <span onClick={()=>handlemodal()} className="circle-overlay">{mymenus.upload}</span>:''}
   </div>
    }
       
      
       
       
    </div>
    </div>
  );
};

export default MyCircle;
