import { useEffect, Suspense } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useQuery } from "@tanstack/react-query";
import { Routes, Route, generatePath } from "react-router-dom";
import React from "react";

import Header from "./Main_Components/Header/Header";
import "./App.css";
import {
  app_menus,
  country,
  iam,
  language,
  name,
  user_img,
} from "./Main_Components/AtomDefined";
import { fetchApplanguage, initial } from "./QueryFeatcher";
import secureLocalStorage from "react-secure-storage";
import Question_Skeleton from "./Main_Components/Questions_skeleton";
import Footer from "./Main_Components/Footer";
import Forgot_Password from "./Main_Components/Forgot_Passoword";
import Exer_Quiz_Hub from "./Main_Components/Exer_Quiz_Hub";
import FooterMen from "./Main_Components/FooterMen";
import Help from "./Main_Components/Help";
const Home = React.lazy(() => import("../src/Main_Components/Home"));
const Register = React.lazy(() => import("./Main_Components/Registration"));
const Dashboard = React.lazy(() => import("./Main_Components/Dashboard"));
const Archive = React.lazy(() => import("./Main_Components/Archieve"));
const Login = React.lazy(() => import("./Main_Components/Login"));
const Profile = React.lazy(() => import("./Main_Components/Profile"));
const TermsAndConditions = React.lazy(() =>
  import("./Main_Components/Terms_and_Conditions")
);
const PrivacyPolicy = React.lazy(() =>
  import("./Main_Components/Privacy_Policy")
);
const What_is_Bujho = React.lazy(() =>
  import("./Main_Components/What_is_Bujho")
);
const My_EX_Quiz = React.lazy(() => import("./Main_Components/My_EX_Quiz"));

function App() {
  const mylanguage = useRecoilValue(language);
  const mycountry = useRecoilValue(country);
  const [myname, setName] = useRecoilState(name);

  const [whatiam, setwhatiam] = useRecoilState(iam);
  const setappmenus = useSetRecoilState(app_menus);
  const setImage = useSetRecoilState(user_img);

  const { status: u_status, data: u_data } = useQuery(
    ["initial", mylanguage, whatiam, mycountry],
    () => initial("initial"),
    {
      staleTime: Infinity,
    }
  );
  useEffect(() => {
    if (u_status === "success") {
      if (u_data !== 5) {
     
        setName(u_data.name);
        setImage(u_data.image_url);
     
      }
    }
  }, [u_status]);

  const { status, data } = useQuery(
    ["Applanguage", mylanguage],
    () => fetchApplanguage("Applanguage", mylanguage),
    {
      staleTime: Infinity,
      enabled: mylanguage !== "",
    }
  );

  useEffect(() => {
    if (status === "success" && data) {
      setappmenus(data[0]);
    }
  }, [status, data]);

  return (
    <>
      <Header />
      <Routes>
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/`}
          element={
            <Suspense fallback={<Question_Skeleton />}>
              {" "}
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/dashboard`}
          element={
            <Suspense fallback={<Question_Skeleton />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/archive`}
          element={
            <Suspense fallback={<Question_Skeleton />}>
              <Archive />
            </Suspense>
          }
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/terms_conditions`}
          element={
            <Suspense fallback={<Question_Skeleton />}>
              <TermsAndConditions />
            </Suspense>
          }
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/privacy_policy`}
          element={
            <Suspense fallback={<Question_Skeleton />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/what-is-bujho`}
          element={
            <Suspense fallback={<Question_Skeleton />}>
              <What_is_Bujho />
            </Suspense>
          }
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/quiz`}
          element={<Exer_Quiz_Hub />}
        />
        <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/exercise`}
          element={<Exer_Quiz_Hub />}
        />
             <Route
          path={`/${mylanguage}/${mycountry}/${whatiam}/help`}
          element={<Help/>}
        />

        {myname === "" ? (
          <>
            <Route
              path={`/${mylanguage}/${mycountry}/${whatiam}/register`}
              element={
                <Suspense fallback={<Question_Skeleton />}>
                  <Register />
                </Suspense>
              }
            />
            <Route
              path={`/${mylanguage}/${mycountry}/${whatiam}/fg_pass`}
              element={
                <Suspense fallback={<Question_Skeleton />}>
                  <Forgot_Password />
                </Suspense>
              }
            />
            <Route
              path={`/${mylanguage}/${mycountry}/${whatiam}/login`}
              element={
                <Suspense fallback={<Question_Skeleton />}>
                  <Login />
                </Suspense>
              }
            />
          </>
        ) : (
          <>
            <Route
              path={`/${mylanguage}/${mycountry}/${whatiam}/profile`}
              element={
                <Suspense fallback={<Question_Skeleton />}>
                  <Profile />
                </Suspense>
              }
            />
            <Route
              path={`/${mylanguage}/${mycountry}/${whatiam}/my-quiz-exercise`}
              element={
                <Suspense fallback={<Question_Skeleton />}>
                  <My_EX_Quiz />
                </Suspense>
              }
            />
          </>
        )}
      </Routes>
 
      <div style={{position:'sticky',zIndex:'1',bottom:'0'}}>  <FooterMen/></div>

      {/* Yaha Firebase Component Tha */}
    </>
  );
}

export default App;
