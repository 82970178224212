import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Container,Row, Col, Card } from "react-bootstrap"
function Question_Skeleton()
{
    return(
        <Container>
        <Row>
          <Col>
      
                <div style={{marginTop:'20px', padding:'20px'}}>
             <SkeletonTheme >       
            <Skeleton height={50}/>
            <Skeleton height={20} style={{marginTop:'30px'}} />
            <Skeleton height={20} width={'80%'}/>
            <Skeleton height={20} width={'80%'}/>
            <Skeleton height={30} style={{marginTop:'20px'}} width={'60%'} />
            <Skeleton height={30} style={{marginTop:'20px'}}  width={'60%'} />
            <Skeleton height={30} style={{marginTop:'20px'}}  width={'60%'} />
            <Skeleton height={30} style={{marginTop:'20px'}}  width={'60%'} />


            
            </SkeletonTheme>
            </div>
           
            </Col>
            </Row>
            </Container>
    )
}

export default Question_Skeleton