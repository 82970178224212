
import { useQuery } from "@tanstack/react-query";
import { fetchResult } from "../QueryFeatcher";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import Question_Skeleton from "./Questions_skeleton";
import User_Pic from "./User_Pic";
function SeeResult(props)
{

  
const[isshow,setshow]     = useState(null);
const mydata = new FormData()
mydata.append('paper_id',props.id)
    const { status, data, isLoading } = useQuery({
        queryKey: ["fetchResult", props.id],
        queryFn: () => fetchResult("fetchResult", mydata),
        cacheTime: Infinity,
        staleTime: Infinity,
      });
useEffect(() => {
  if(status==='success')
  {
    if(data.data==='not_end')
    {
        setshow(true);
    }
    else
    {
      setshow(false);
    }
  }
}, [status])

    return(
        <div >
          {isLoading?<Question_Skeleton/>:null}
          {isshow?<Alert variant="info" style={{marginTop:'10px'}}>The Quiz is Active Yet!!.<br/>
          Your Score is : {data.score}<br/>
          Your Percentage is : {data.percentage}<br/>
            <br/>Percentile and Rank will be Available from <strong>{data.date}</strong> onwards.</Alert>:null}
          {isshow===false?
          <div>
            <Card style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',marginTop:'15px',padding:'5px'}}>
              <table>
        <tr>  <th>Rank</th>
            
            <th style={{textAlign:'center'}}>Name</th>
            <th style={{textAlign:'center'}}>Score</th>
            <th style={{textAlign:'center'}}>%</th>
           { data.content.some(item => 'percentile' in item)? <th style={{textAlign:'center'}}>Percentile</th>:null }
            <th style={{textAlign:'center'}}>Time</th>

         </tr>   
            
  {data.content.map((item, index) => (
    <tr style={{color:item.matched==='yes'?'red':'black',borderBottom:'1px solid',marginTop:'10px'}}>
    <td className="animate-charcter" style={{fontSize:'22px',fontWeight:'500',textAlign:'center'}}>{item.hasOwnProperty('rank')?item.rank : index + 1} </td>
    <td><div style={{display:'flex',alignItems:'center'}}><div> <User_Pic name={item.name} image={item.image_url} /></div> <div style={{marginLeft:'5px'}}>{item.name} {item.surname} <br/>{item.city}<br/><small style={{color:'#848786'}}>with {item.count - 1} others</small></div></div></td>
     <td style={{textAlign:'center'}}>  {item.score} </td>
    <td style={{textAlign:'center'}} > {item.percentage+' '}% </td>
    {item.percentile?<td style={{textAlign:'center'}} > {item.percentile+' '}% </td>:null}
    <td style={{textAlign:'center'}} > {item.time.split(':').slice(1).join(':')} </td>
    
    </tr>
  ))}


</table>
     </Card>
             </div>
          :null  
        }
        </div>
    )
}
export default SeeResult