import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { app_menus, country, iam, language, last_url, name } from "./AtomDefined";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchQuestions, submitOption } from "../QueryFeatcher";
import { useEffect, useState } from "react";
import { Container, Spinner, Row, Col, Card, Button, Alert, Accordion, Image } from "react-bootstrap";
import { Check2Circle,   XCircle } from "react-bootstrap-icons";
import QuizTimer from "./Header/QuizTimer";
import SeeResult from "./SeeResult";
import { useRef } from "react";
import ShareButton from "./ShareButton";
import { isSameQuarter } from "date-fns";
function SeeQuiz() {
  const [isqueryData, setQuerydata] = useState(null);
  const [mytime, settime] = useState(null);
  const [exetime, setexetime] = useState(null);
  const mymenus = useRecoilValue(app_menus);
  const [isitDown, setdown] = useState(null);
  const navigate = useNavigate();
  const mycountry = useRecoilValue(country);
  const who_i_am = useRecoilValue(iam);
  const myname = useRecoilValue(name);
  const mylocation = useLocation();
  const mylanguage = useRecoilValue(language);
  const queryParams = new URLSearchParams(mylocation.search);
  const urlArray = mylocation.pathname.split("/").filter((part) => part !== "");
  const cat = queryParams.get("cat");
  const title = queryParams.get("title");
  const id = queryParams.get("id");
  const fid = queryParams.get("fid");
  const [showrank,setshowrank] = useState(false);
  const seturl = useSetRecoilState(last_url);
  const mydata = new FormData();
  mydata.append("see_Questions", "see_questions");
  mydata.append("title", title);
  mydata.append("id", id);
  mydata.append("category", cat);
  mydata.append("type", urlArray[3]);
  mydata.append("language", mylanguage);
  const myclick = useRef(null);
  const mysubmit = useRef(null);
  useEffect(() => {
    if(myname==='')
    {
      seturl(mylocation);
    }
    else
    {
      seturl('');
    }
  
    
  }, [myname]);
  

  const { status, data, isLoading, refetch } = useQuery({
    queryKey: ["fetch_question", mylanguage],
    queryFn: () => fetchQuestions("fetch_question", mydata),
    enabled:myname!=='',
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (status === "success") {
      setQuerydata(data);
      if (
        !data.question_data.some((item) => item.hasOwnProperty("right_answer"))
      ) {
        if (urlArray[3] === "exercise") {
          setdown(false);
          settime(data.time);
        } else {
          setdown(true);
          settime(data.time);
        }
      }
    }
  }, [status, data]);

  const [what_is, setwhatis] = useState("");
  useEffect(() => {
    if (isqueryData?.question_data) {
      const elementToUpdate = isqueryData.question_data.find(
        (item) => item.my_answer !== null
      );

      if (elementToUpdate) {
        setwhatis(123);
      } else {
        setwhatis(321);
      }
    }
  }, [isqueryData]);
  async function submit_exe(paper_id) {
    if (
      isqueryData.question_data.some(
        (item) => item.hasOwnProperty("my_answer") && item.my_answer !== null
      )
    ) {
      const sub = new FormData();
      sub.append("submit", "submit");
      sub.append("time", exetime);
      sub.append("id", paper_id);
      const url =
        "https://bujho-quiz.com/server_files/exer_quiz/submit_exe_quiz.php";
      try {
        const response = await fetch(url, {
          method: "POST",
          credentials: "include",
          body: sub, // Make sure 'data' is a FormData object or a serialized string
        });
        if (response.ok) {
          const data = await response.json();
          if (data === 1) {
            settime(null);
            alert("successfully Submitted");
            refetch();
            window.scrollTo(0, 0);
            const audio = mysubmit.current;
           audio.play();
          }
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    } else {
      alert("Answer Atleast one Questions");
    }
  }
  const handleTimeChange = (formattedTime) => {
    setexetime(formattedTime);
    // Do something with the formatted time, e.g., update state or display it
  };
  const RenderOptions = (props) => {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8];

    async function submit_option(
      paper_id,
      question_id,
      option,
      type,
      what_is,
      operation
    ) {
      const mydata = new FormData();
      mydata.append("type", type);
      mydata.append("question_id", question_id);
      mydata.append("paper_id", paper_id);
      mydata.append("option", option);
      mydata.append("what_is", what_is);
      mydata.append("operation", operation);
      mydata.append("time", exetime);

      const url =
        "https://bujho-quiz.com/server_files/exer_quiz/submit_option.php";
      try {
        const response = await fetch(url, {
          method: "POST",
          credentials: "include",
          body: mydata, // Make sure 'data' is a FormData object or a serialized string
        });
        if (response.ok) {
          const data = await response.json();
          if (data === 1) {
            const elementToUpdate = isqueryData.question_data.find(
              (item) => item.id === question_id
            );

            if (elementToUpdate) {
              // Create a copy of the element and update 'my_answer'
              const updatedElement = {
                ...elementToUpdate,
                my_answer: "op" + option,
              };
              // Find the index of the element in the array
              const indexToUpdate =
                isqueryData.question_data.indexOf(elementToUpdate);

              // Create a new array with the updated element
              const updatedQuestionData = [
                ...isqueryData.question_data.slice(0, indexToUpdate),
                updatedElement,
                ...isqueryData.question_data.slice(indexToUpdate + 1),
              ];
              const audio = myclick.current;audio.play();
              setQuerydata((prevData) => ({
                ...prevData,
                question_data: updatedQuestionData,
              }));
       

            }
          }
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    return (
      <div>
        {isqueryData.what_is === "no"
          ? numbers.map((number) => (
              <div key={number}>
                {isqueryData.question_data[props.index_val][
                  "op" + number + "_" + mylanguage
                ] !== "" ? (
                 <> <div
                    onClick={() =>
                      submit_option(
                        props.paper_id,
                        isqueryData.question_data[props.index_val].id,
                        number,
                        urlArray[3],
                        what_is,
                        isqueryData.question_data[props.index_val].my_answer
                          ? "update"
                          : "insert"
                      )
                    }
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        isqueryData.question_data[props.index_val].my_answer ===
                        "op" + number
                          ? "#c9c05f"
                          : "white",
                      border: "1px solid black",
                      marginTop: "10px",
                      padding: "5px",
                      borderRadius: "50px",
                    }}
                  >
                    {" "}
                    {
                      isqueryData.question_data[props.index_val][
                        "op" + number + "_" + mylanguage
                      ]
                    }
                    
                    {isqueryData.question_datapro}
                    
                    {" "}
                    
                  </div>
                  {isqueryData.question_data[props.index_val][
                  "op" + number + "i" ] && isqueryData.question_data[props.index_val][
                  "op" + number + "i" ] !== "" ?
                  <img className="img-thumbnail" width='50%' src={`https://bujho-quiz.com/server_files/question_images/${isqueryData.question_data[props.index_val][
                    "op" + number + "i" ]}`}/>
               
                  
                  :''}
                
</>
                ) : null}
              </div>
            ))
          : numbers.map((number) => (
              <div key={number}>
               {isqueryData.question_data[props.index_val][
  "op" + number + "_" + mylanguage
] !== "" ? (
  <div>
    <div
      style={{
        cursor: "pointer",
        backgroundColor:
          isqueryData.question_data[props.index_val].right_answer ===
          "op" + number
            ? "#2b7a2c"
            : isqueryData.question_data[props.index_val].my_answer !== null &&
              isqueryData.question_data[props.index_val].my_answer ===
                "op" + number
            ? "#b80707"
            : "white",
        color:
          isqueryData.question_data[props.index_val].right_answer ===
          "op" + number
            ? "white"
            : isqueryData.question_data[props.index_val].my_answer !== null &&
              isqueryData.question_data[props.index_val].my_answer ===
                "op" + number
            ? "white"
            : "black",
        border: "1px solid black",
        marginTop: "10px",
        padding: "5px",
        borderRadius: "50px",
      }}
    >
      {isqueryData.question_data[props.index_val][
        "op" + number + "_" + mylanguage
      ]}
      {isqueryData.question_data[props.index_val].right_answer === "op" + number ? (
        <Check2Circle />
      ) : isqueryData.question_data[props.index_val].my_answer !== null &&
        isqueryData.question_data[props.index_val].my_answer === "op" + number ? (
        <XCircle />
      ) : null}
    </div>

    {isqueryData.question_data[props.index_val]["op" + number + "i"] && isqueryData.question_data[props.index_val]["op" + number + "i"] !== "" ? (
      <img
        className="img-thumbnail"
        width="50%"
        src={`https://bujho-quiz.com/server_files/question_images/${isqueryData.question_data[props.index_val]["op" + number + "i"]}`}
      />
    ) : null}
  </div>
) : null}


              </div>
            ))}
      </div>
    );
  };
  return myname===''  ? (
    <Container>
      <Row>
        <Col  sm={{ span: "8", offset: "2" }}>
        <Alert>Please Register or Login</Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    isLoading ? <Spinner/> :
    <Container>
      <Row>
        <Col sm={{ span: "8", offset: "2" }}>
          <h5 style={{ display: "flex" }}>
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  "/" +
                    mylanguage +
                    "/" +
                    mycountry +
                    "/" +
                    who_i_am +
                    "/" +
                    urlArray[3]
                )
              }
            >
              {" "}
              {urlArray[3] === "exercise" ? mymenus.exercise : mymenus.quiz}
            </div>
            /{" "}
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  "/" +
                    mylanguage +
                    "/" +
                    mycountry +
                    "/" +
                    who_i_am +
                    "/" +
                    urlArray[3] +
                    "/?cat=" +
                    cat
                )
              }
            >
              {cat}
            </div>
            / <div>{title}</div>
          </h5>
              
          {mytime !== null ? (
            <div
              style={{
                textAlign: "center",
                zIndex: "1",
                position: "sticky",
                top: "0",
                backgroundColor: urlArray[3]==='quiz' && exetime<'00:10'?'white':'#007694',
                color: urlArray[3]==='quiz' && exetime<'00:10'?'red':'white',
                border: "1px solid black",
                borderRadius: "50px",
                padding: "5px",
              }}
            >
              {" "}
              <QuizTimer
                startTime={mytime}
                isCountdown={isitDown}
                endTime={"00:00"}
                onTimeup={() => {
                  if (
                    isqueryData.question_data.some((item) => item.hasOwnProperty("my_answer") && item.my_answer !== null)){
                      alert('Your Times up!! Quiz is now Submitting...');
                      submit_exe(id, urlArray[3]);
                  }
                  else
                  {
                      alert('Your Times up!! You did not answer any Question.');
                      navigate(
                        "/" +
                          mylanguage +
                          "/" +
                          mycountry +
                          "/" +
                          who_i_am +
                          "/" +
                          urlArray[3] +
                          "/?cat=" +
                          cat
                      )
                      
                  }
                }}
                onTimeChange={handleTimeChange} 
                
              />
            </div>
          ) : (
            <div
            style={{
              textAlign: "center",
              zIndex: "1",
              position: "sticky",
              top: "0",
              backgroundColor: "#007694",
              color: "white",
              border: "1px solid black",
              borderRadius: "50px",
              padding: "5px",
              cursor:'pointer'
            }} onClick={()=>setshowrank(!showrank)}
          >{showrank?'See Questions':'See Your Rank'}</div>
          )}
     {!showrank ? (
  <>
    {isqueryData?.question_data.map((item, index) => (
      <Card
        key={index}
        style={{
          backgroundColor: index % 2 === 0 ? "#f2f7fc" : "white",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          marginTop: "15px",
          padding: "10px",
        }}
      >
        {item[`question_${mylanguage}`] === "" ? (
          mymenus.q_nl
        ) : (
          <>
            <div style={{ fontWeight: "bold" }}>
              {index + 1}
              {". "} {item[`question_${mylanguage}`]}
            </div>
            <div style={{fontSize: "12px",display:'flex', justifyContent:'space-between'}}>
            <div>Marks:{item.difficulty}</div>
            {item.hasOwnProperty("my_answer") ? (
              item.my_answer === null ? (
                <div>
                  {mymenus.nt_ans}
                </div>
              ) : null
            ) : null}
            
            </div>
            {item.question_image?<img width='50%' className="img-thumbnail" src={`https://bujho-quiz.com/server_files/question_images/${item.question_image}`}/>:null}
            <hr />
            <RenderOptions index_val={index} paper_id={id} />
            {item.hasOwnProperty(`explanation_${mylanguage}`) && item[`explanation_${mylanguage}`]!==''?
            <Accordion style={{marginTop:'15px',}} >
              <Accordion.Item eventKey={index}>
                  <Accordion.Header><strong style={{color:'blue'}}>Explanation</strong></Accordion.Header>
                  <Accordion.Body>
                    <div style={{textAlign:'justify'}}>{item[`explanation_${mylanguage}`]}</div>
                   {item.refrence?<div style={{textAlign:'right'}}><i>{item.refrence}</i></div>:null}
                   {item.link?<div style={{textAlign:'right'}}><a href={item.link}>{item.link}</a></div>:null}
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          :null}
          </>
        )}

        
      </Card>
    ))}
{isqueryData && isqueryData.question_data && isqueryData.question_data.some((item) => "right_answer" in item) ? null : (
  <Button
    className="text-center"
    style={{
      backgroundColor: "#007694",
      marginTop: "10px",
      marginLeft: "42%",
    }}
    onClick={() => submit_exe(id, urlArray[3])}
  >
    {mymenus.submit}
  </Button>
)}

  </>
) : (
  <>
    <SeeResult id={id}/>
  </>
)}

        </Col>
      </Row>
      <audio ref={myclick}>
        <source src='/click.mp3' type="audio/mpeg" />
      </audio>
      <audio ref={mysubmit}>
        <source src='/submit.mp3' type="audio/mpeg" />
      </audio>
     
      {isqueryData && isqueryData.question_data && isqueryData.question_data.some((item) => "right_answer" in item) ? 
      
      <Row><Col className="text-center mt-4">
      <ShareButton myname = {myname} type={urlArray[3]}/></Col></Row> : null}  
    
    </Container>
    
    
  );
}
export default SeeQuiz;
