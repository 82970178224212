import { useRecoilState, useSetRecoilState } from "recoil";
import {
  app_menus,
  country,
  iam,
  language,
  name,
  user_img,
} from "../AtomDefined";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Container, Row, Col, Button, Dropdown, Spinner } from "react-bootstrap";
import { useState } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import logom from "../../../src/logo192.png";
import MyCircle from "../Circle";
import { useQuery } from "@tanstack/react-query";
import { countQuiz_Exe } from "../../QueryFeatcher";
function Header() {
  const [mylang, setlanguage] = useRecoilState(language);
  const [mycountry, setcountry] = useRecoilState(country);
  const [myname, setmyname] = useRecoilState(name);
  const [isiam, setiam] = useRecoilState(iam);
  const [mymenus, setappmenus] = useRecoilState(app_menus);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const setmyimage = useSetRecoilState(user_img);
   
  async function mylogout() {
    const url = "https://bujho-quiz.com/server_files/logout.php";
    const logoutdata = new FormData();
    logoutdata.append("logoutini", "logout");
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: logoutdata,
      });

      if (response.ok) {
        const thissess = await response.json();

        if (thissess === 1) {
          setmyname("");
          setmyimage("");
          navigate("/" + mylang + "/" + mycountry + "/" + isiam + "/login");
        }
      }
    } catch (error) {
    } finally {
    }
  }

  function navigateme(myurl) {
    navigate(myurl);
  }
 const { status, data, isLoading } = useQuery({
    queryKey: ["count_exe_quiz"],
    staleTime: Infinity,
    queryFn: () => countQuiz_Exe("count_exe_quiz"), enabled:myname!==''
  }); 
  return (
    <div>
      <Container className="my_background" fluid>
      <Row>
       <Col xs={{span:'6',offset:'3'}} className="text-center">
        <img src={logom} height={100} className="img-logo" />
       </Col>
      </Row>
      <Row>
          <Col className="text-end">
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              style={{ width: "280px" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MyCircle />

                      <div style={{ textAlign: "center", marginLeft: "20px" }}>
                        {mymenus.greeting},{" "}
                        {myname === "" ? mymenus.guest : myname}{" "}
                      </div>
                    </div>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <hr />
              <Offcanvas.Body>
                {myname === "" ? (
                  <>
                    <div>
                      <Button
                        variant="outline"
                        onClick={() => {
                          navigateme(
                            `/${mylang}/${mycountry}/${isiam}/register/`
                          );
                          setShow(false);
                        }}
                      >
                        {mymenus.register}
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outline"
                        onClick={() => {
                          navigate(`/${mylang}/${mycountry}/${isiam}/login/`);
                          setShow(false);
                        }}
                      >
                        {mymenus.login}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {" "}
                      <strong>{mymenus.wel}</strong>
                    </div>
                    <div>
                      <Button
                        variant="outline"
                        onClick={() => {
                          navigate(`/${mylang}/${mycountry}/${isiam}/my-quiz-exercise/?type=exercise`);
                          setShow(false);
                        }}
                      >
                        {'My Exercise'}{isLoading?<Spinner/>:' ('+data.exercise+')'}
                      </Button>
                      <div>
                      <Button
                        variant="outline"
                        onClick={() => {
                          navigate(`/${mylang}/${mycountry}/${isiam}/my-quiz-exercise/?type=quiz`);
                          setShow(false);
                        }}
                      >
                        {'My Quiz'}{isLoading?<Spinner/>:' ('+data.quiz+')'}
                      </Button>
                    </div>
                    <hr/>
                    </div>
                    <div>
                      <Button
                        variant="outline"
                        onClick={() => {
                          navigate(`/${mylang}/${mycountry}/${isiam}/profile/`);
                          setShow(false);
                        }}
                      >
                        {mymenus.profile}
                      </Button>
                    </div>
                    <div>
                      {" "}
                      <Button
                        variant="outline"
                        onClick={() => {
                          mylogout();
                          setShow(false);
                        }}
                      >
                        {mymenus.lo}
                      </Button>
                    </div>
                  </>
                )}
                <Dropdown>
                  <Dropdown.Toggle variant="outline" id="dropdown-basic">
                    {mylang === "hi" ? "हिंदी" : "English"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        <Offcanvas
                          show={show}
                          onHide={handleClose}
                          placement="end"
                          style={{ width: "280px" }}
                        ></Offcanvas>;
                        secureLocalStorage.setItem("language", "hi");
                        setlanguage("hi");
                        setShow(false);
                      }}
                    >
                      हिन्दी
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        secureLocalStorage.setItem("language", "en");
                        setlanguage("en");
                        setShow(false);
                      }}
                    >
                      English
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant="outline"
                  onClick={() => {
                    setcountry("");
                    setmyname("");
                    setiam("");
                    setappmenus("");
                    setlanguage("");
                    secureLocalStorage.clear();
                    navigate("/");
                  }}
                >
                  {mymenus.app_reset}
                </Button>
              </Offcanvas.Body>
              <div className="my_background" style={{color:'white',}}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                  
                  
                    <div
                            style={{cursor:'pointer'}}             onClick={() =>
                        navigate(
                          "/" +
                            mylang +
                            "/" +
                            mycountry +
                            "/" +
                            isiam +
                            "/what-is-bujho"
                        )
                      }
                    >
                      {"About"}
                    </div>
                    <div style={{cursor:'pointer'}} onClick={()=>navigate(mylang+'/'+mycountry+'/'+isiam+'/help')} >
                      Help
                    </div>
                    <div style={{cursor:'pointer'}}  onClick={()=>navigate(mylang+'/'+mycountry+'/'+isiam+'/terms_conditions')}>{mymenus.terms}</div> 
                    </div>
                    <div style={{display:'flex',justifyContent:'space-evenly',cursor:'pointer'}}>
                     <div  onClick={()=>navigate(mylang+'/'+mycountry+'/'+isiam+'/privacy_policy')}>{mymenus.privacy}</div>
                     <div >Developed By</div>
                     </div>
                     <div>
                     <div style={{fontSize:'15px',textAlign:'center'}}>© {mymenus.copyright} 2023, <span className="link">www.bujho-quiz.com</span> </div>
                 
                </div>
              </div>
            </Offcanvas>

            <Button
              onClick={handleShow}
              variant="outline"
              style={{
                color: "white",
                fontSize: "18px",
                border: "1px solid white",
              }}
            >
              {mymenus.greeting}, {myname === "" ? mymenus.guest : myname}{" "}
              <CaretDownFill fill="white" size={15} />
            </Button>
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default Header;
