import { formatDistanceStrict } from "date-fns";

const fetchApplanguage = async (key, lan) => {
  const url = 'https://bujho-quiz.com/server_files/basic.php';
  const formData = new FormData();
  formData.append('language', lan);

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      // Include credentials (cookies) in the request
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};
const initial = async (key) => {
  const url = 'https://bujho-quiz.com/server_files/user_check.php';
  const formData = new FormData();
  formData.append('initial','user_check');

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      credentials: 'include', // Include credentials (cookies) in the request
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};


    const fetchtodayQuestion = async (key, lan,whoiam,mydate) => {
    const url = 'https://bujho-quiz.com/server_files/today_question.php';
    const formData = new FormData();
    formData.append('today_question', key);
    formData.append('language', lan);
    formData.append('who_i_am', whoiam);
    formData.append('mydate', mydate);
    
    try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      credentials:'include',
    });
    return await response.json();
  } catch (message) {
    return console.error(message);
  }
  };

  const fetchpercent = async (key, lan,whoiam,mydate) => {
    const url = 'https://bujho-quiz.com/server_files/todays_percent.php';
    const formData = new FormData();
    formData.append('question_percent', key);
    formData.append('language', lan);
    formData.append('who_i_am', whoiam);
    formData.append('mydate', mydate);
    
    try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    return await response.json();
  } catch (message) {
    return console.error(message);
  }
  };
  const fetchlongstrike = async (key,whoiam) => {
    const url = 'https://bujho-quiz.com/server_files/longstrike.php';
    const formData = new FormData();
    formData.append('long_strike', key);
     formData.append('who_i_am', whoiam);
     
    try {
    const response = await fetch(url, {
      method: 'POST',
      credentials:'include',
      body: formData,
    });
    return await response.json();
  } catch (message) {
    return console.error(message);
  }
  };

  const fetchActivestrike = async (key,whoiam) => {
    const url = 'https://bujho-quiz.com/server_files/activestrike.php';
    const formData = new FormData();
    formData.append('active_strike', key);
     formData.append('who_i_am', whoiam);
     
    try {
    const response = await fetch(url, {
      method: 'POST',
      credentials:'include',
      body: formData,
    });
    return await response.json();
  } catch (message) {
    return console.error(message);
  }
  };

  const fetchmostactive = async (key,whoiam) => {
    const url = 'https://bujho-quiz.com/server_files/mostactive.php';
    const formData = new FormData();
    formData.append('most_active', key);
     formData.append('who_i_am', whoiam);
     
    try {
    const response = await fetch(url, {
      method: 'POST',
      credentials:'include',
      body: formData,
    });
    return await response.json();
  } catch (message) {
    return console.error(message);
  }
  };

  const fetchmyperformance = async (key,whoiam) => {
    const url = 'https://bujho-quiz.com/server_files/my_performance.php';
    const formData = new FormData();
    formData.append('my_performance', key);
     formData.append('who_i_am', whoiam);
     
    try {
    const response = await fetch(url, {
      method: 'POST',
      credentials:'include',
      body: formData,
    });
    return await response.json();
  } catch (message) {
    return console.error(message);
  }
  };
  const fetchprofile = async () => {
    const url = "https://bujho-quiz.com/server_files/profile.php";

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
      });
      return await response.json();

    } catch (message) {
      return console.error(message);
    }
  };

  const fetchExer_Quiz = async (key, data) => {
    const url = "https://bujho-quiz.com/server_files/exer_quiz/exer_quiz.php";
    
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials:'include',
        body: data, // Make sure 'data' is a FormData object or a serialized string
      });
      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchExer_Quiz_Cat = async (key, data) => {
    const url = "https://bujho-quiz.com/server_files/exer_quiz/exer_quiz_category.php";
     try {
      const response = await fetch(url, {
        method: "POST",
        credentials:'include',
        body: data, // Make sure 'data' is a FormData object or a serialized string
      });
      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };



  const fetchQuestions = async (key, data) => {
    const url = "https://bujho-quiz.com/server_files/exer_quiz/fetch_questions.php";
     try {
      const response = await fetch(url, {
        method: "POST",
        credentials:'include',
        body: data, // Make sure 'data' is a FormData object or a serialized string
      });
      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const submitOption = async (data) => {
    const mydata = new FormData();
    mydata.append('type',data.type);
    mydata.append('question_id',data.question_id);
    mydata.append('paper_id',data.paper_id);
    mydata.append('option',data.option);
    mydata.append('what_is',data.what_is);
    mydata.append('operation',data.operation);
    
    const url = "https://bujho-quiz.com/server_files/exer_quiz/submit_option.php";
     try {
      const response = await fetch(url, {
        method: "POST",
        credentials:'include',
        body:   mydata  , // Make sure 'data' is a FormData object or a serialized string
      });
      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };


  const countQuiz_Exe = async () => {
    const url = "https://bujho-quiz.com/server_files/exer_quiz/countQuiz.php";

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
      });
      return await response.json();

    } catch (message) {
      return console.error(message);
    }
  };
  const fetchResult = async (key,data) => {
    const url = "https://bujho-quiz.com/server_files/exer_quiz/calculateRank.php";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });
      return await response.json();

    } catch (message) {
      return console.error(message);
    }
  };

  const fetchmyExQuiz = async (key,data) => {
    const url = "https://bujho-quiz.com/server_files/exer_quiz/my_ex_quiz.php";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        credentials: "include",
      });
      return await response.json();

    } catch (message) {
      return console.error(message);
    }
  };
  

export {fetchmyExQuiz,fetchResult,countQuiz_Exe,submitOption,fetchQuestions,fetchExer_Quiz_Cat,fetchExer_Quiz,fetchprofile,fetchmyperformance,fetchmostactive,fetchlongstrike,fetchActivestrike,initial,fetchpercent,fetchApplanguage, fetchtodayQuestion}