import React, { useState, useEffect } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import logo from '../logo192.png';
import { useRecoilValue } from "recoil";
import { app_menus } from "./AtomDefined";
import { Download } from "react-bootstrap-icons";

function Download_Prompt() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const mymenus = useRecoilValue(app_menus);
  const [show, setShow] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  const handleBeforeInstallPrompt = (event) => {
    // Prevent the default browser prompt
    setShow(true);
    event.preventDefault();
    // Store the event for later use
    setDeferredPrompt(event);
  };

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      // Show the browser prompt
      deferredPrompt.prompt();
      // Wait for the user's response
      deferredPrompt.userChoice.then((choiceResult) => {
        // Reset the deferred prompt
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleAppInstalled = () => {
    setIsAppInstalled(true);
  };


  return (
    <>
      <Offcanvas
        show={!isAppInstalled && show}
        onHide={() => setShow(false)}
        placement="bottom"
        style={{ marginRight: '0px', height: '15%', right: '0', border: '1px solid black', borderRadius: '20px' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ backgroundColor: '#007694', padding: '1px', borderRadius: '50%' }}
                  src={logo}
                  height={'60px'}
                />
                <div style={{fontSize:'18px', textAlign: 'center', marginLeft: '20px' }}>{mymenus.download_app}</div>
                <Button style={{ marginLeft: '10px' }} onClick={handleAddToHomeScreen}>
                  <Download width={30} /> {mymenus.download_button}
                </Button>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
}

export default Download_Prompt;
