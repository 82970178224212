import { useRecoilState } from "recoil";
import { country, iam, language } from "../AtomDefined";
import {  useNavigate } from "react-router-dom";
import App from "../../App";
import InitialSetup from "./InitialSetup";
import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";

import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

function FirstInitial()
{
    const [mylanguage, setlanguage] = useRecoilState(language);
    const [mycountry, setcountry] = useRecoilState(country);
    const [whoiam,setiam] = useRecoilState(iam);
    const pathname = window.location.pathname;
    const answer_array = pathname.split('/').filter(Boolean);
    const queryClient = new QueryClient({});
    const navigate = useNavigate();
    useEffect(() => {
        if(answer_array[0])
        { 
   
                  
          setlanguage(answer_array[0]);
          setcountry(answer_array[1]);
          navigate(pathname+ window.location.search);
        }
    
      
    }, [])

    
    useEffect(() => {
 
      if(!answer_array[3])
      {
        
      if(mylanguage!=='')
      {
        navigate(mylanguage+'/'+mycountry+'/'+whoiam+'/'+window.location.search);
      }
    }
    else
    {
      const segments = pathname.split('/'); // Split the pathname into an array of segments
      const newPathname = segments.slice(4).join('/');
   
           navigate('/'+mylanguage+'/'+mycountry+'/'+whoiam+'/'+newPathname+window.location.search);
    }
    
    }, [mylanguage])
    
    


    return(
      

<>
   

    
   {mylanguage!==''?(<><QueryClientProvider client={queryClient}>

<App/></QueryClientProvider></>):<InitialSetup></InitialSetup>}
</>   
    )
    
}

export default FirstInitial