import React, { useState, useEffect } from 'react';
import { AlarmFill } from 'react-bootstrap-icons';

const timeToSeconds = (timeString) => {
  const [minutes, seconds] = timeString.split(':').map((value) => parseInt(value, 10));
  return minutes * 60 + seconds;
};

const secondsToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const QuizTimer = ({ startTime, onTimeup, onTimeChange, isCountdown }) => {

  const maxCountupTime = timeToSeconds('59:59');
  const [currentTime, setCurrentTime] = useState(timeToSeconds(startTime));
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = isCountdown ? prevTime - 1 : prevTime + 1;
        if (isCountdown && newTime <= 0) {
          setIsTimeUp(true);
          clearInterval(interval);
          onTimeup();
        } else if (!isCountdown && newTime >= maxCountupTime) {
          setIsTimeUp(true);
          clearInterval(interval);
          onTimeup();
        }
        onTimeChange(secondsToTime(newTime));
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isCountdown, maxCountupTime, onTimeup, onTimeChange]);

  return (
    <div>
      {isTimeUp ? (
        <h5>Time's up!</h5>
      ) : (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}> <AlarmFill style={{marginRight:'2px'}} size={20}/>{secondsToTime(currentTime)}</div>
      )}
    </div>
  );
};

export default QuizTimer;
