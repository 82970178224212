import { useState, useRef } from 'react';
import { Facebook, Link, Share,  Whatsapp } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

function ShareButton(props) {
  const name = props.myname;
  const type = props.type;
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
   const shareText = "*"+name+'* Challanged You for the '+type+'. \n CheckOut Following Link  \n ';
  const shareLink = window.location.href;
  
  const encodedMessage = encodeURIComponent(shareText + " " + shareLink + '\n*Enjoy Quizzing..*') ;
  function handleShare (media)  {
   if(media==='whatsapp')
   {
    const whatsappURL = `https://wa.me/?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
   }
   else if(media==='facebook')
   {
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}&quote=${shareText}`;
    window.open(facebookURL, '_blank');
   }
   else
   {
    navigator.clipboard.writeText(shareLink)
   }
  };



  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div ref={ref}>
      <Button onClick={handleClick}>Challange Your Friend <br/><Share/></Button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Header as="h5">  
          <div  style={{display:'flex'}}>
          <div onClick={()=>handleShare('whatsapp')} style={{cursor:'pointer'}}><Whatsapp color='green' size={25}/></div>
          <div onClick={()=>handleShare('facebook')} style={{marginLeft:'10px', cursor:'pointer'}}><Facebook color='#3b5998' size={25}/> </div>
        <div onClick={()=>handleShare('linkcopy')} style={{marginLeft:'10px', cursor:'pointer'}}><Link color='blue' size={25}/></div>
                 
        </div>
          
          </Popover.Header>
   
        </Popover>
      </Overlay>
    </div>
  );
}

export default ShareButton;