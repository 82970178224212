import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchExer_Quiz_Cat } from "../QueryFeatcher";
import { useRecoilValue } from "recoil";
import { app_menus, country, iam, language } from "./AtomDefined";
import Question_Skeleton from "./Questions_skeleton";
import { Container, Row, Col, Card, Button, Spinner,Form } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
function Category_Wise() {
  const mylocation = useLocation();
  const [page, setPage] = useState(0);
  const mylanguage = useRecoilValue(language);
  const mymenus = useRecoilValue(app_menus);
  const mycountry = useRecoilValue(country);
  const [orderby,setorder] = useState('');
  const [sortby,setsort] = useState('');
  const who_i_am = useRecoilValue(iam);
  const navigate = useNavigate();
  const urlArray = mylocation.pathname.split("/").filter((part) => part !== "");
  const queryParams = new URLSearchParams(mylocation.search);
  const cat = queryParams.get("cat");
  const handleSelectChange = (event) => {
    setorder(event.target.value);
    setPage(0);
    if(event.target.value==='')
    {
      setsort('');
    }
    else
    {
      setsort('asc');
    }
    

  };
  const myform = new FormData();
  myform.append("type", urlArray[3]);
  myform.append("category", cat);
  myform.append("my_role", who_i_am);
  myform.append("language", mylanguage);
  myform.append("page", page);
  myform.append("sortby",sortby);
  myform.append("orderby",orderby);

  useEffect(() => {
    myform.set("page", page);
  }, [page]);

  const { status, data, isLoading, isFetching, isPreviousData } = useQuery({
    queryKey: ["query_quiz_cat_wise", mylanguage, cat, urlArray[3], page,sortby,orderby],
    staleTime: Infinity,
    queryFn: () => fetchExer_Quiz_Cat("query_quiz_cat_wise", myform),
  });

  return (
    <Container style={{minHeight:window.innerHeight-180}}>
      {isLoading ? (
        <Question_Skeleton />
      ) : (

        data!==1?
        <>
          {" "}
          <h5 style={{ margin: "10px 5px 25px 5px", display: "flex" }}>
            {" "}
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  "/" +
                    mylanguage +
                    "/" +
                    mycountry +
                    "/" +
                    who_i_am +
                    "/" +
                    urlArray[3]
                )
              }
            >
              {" "}
              {urlArray[3] === "exercise" ? mymenus.exercise : mymenus.quiz}
            </div>
            <div>/{cat}</div>
          </h5>
          <Row>
            <Col xs='12'>
              <div style={{display:'flex',justifyContent:'space-evenly'}}>
              <div>
              <Form.Select size="sm" onChange={handleSelectChange} value={orderby}>
              <option value={''}>Choose</option>
              <option value={'difficulty'}>Difficulty</option>
              <option value={'max_marks'}>Max Marks</option>
              <option value={'nq'}>Number of Questions</option>
              { urlArray[3]==='quiz'?<>
              <option value={'time'}>Time</option>
              <option value={'end_date'}>Last Date</option></>
            :null}

            </Form.Select>
            </div>
            <div onClick={()=>{ if(orderby===''){setorder('difficulty')} setsort('asc')}} style={{border:'1px solid black',borderRadius:'50px',padding:'5px',cursor:'pointer',backgroundColor:sortby==='asc'?'#c9c05f':'white'}}>Ascending</div>
            <div onClick={()=>{ if(orderby===''){setorder('difficulty')}  setsort('desc')}} style={{border:'1px solid black',borderRadius:'50px',padding:'5px',cursor:'pointer',backgroundColor:sortby==='desc'?'#c9c05f':'white'}}>Descending</div>
            </div>
            </Col>
            
          </Row>
          <Row>
            
            {data.data?.map((item, index) => (
              <Col key={index} sm={{ span: "6" }} xs={12}>
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    marginTop: "15px",
                    padding: "2px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      backgroundColor: "#edf2ef",
                      padding: "5px",
                      borderBottom: "1px solid #a9abaa",
                    }}
                  >
                    {item[`title_${mylanguage}`]}
                  </div>

               <table>
                   <tr> 
                   <th style={{textAlign:'left'}}>{mymenus.difficulty}</th>
                   <th style={{textAlign:'center'}}>{mymenus.max_marks}</th>
                   <th style={{textAlign:'right'}}>{mymenus.nq}</th>
                    </tr>   
<tr>

                    <td style={{textAlign:'left'}}>{item.difficulty}</td>
                    <td style={{textAlign:'center'}}>{item.max_marks}</td>
                    <td style={{textAlign:'right'}}>{item.questions_count}</td>
                    </tr>

                  {urlArray[3] !== "exercise" ? ( <>
 
 <tr>                    
                      <th style={{textAlign:'left'}}>{mymenus.st_date}</th>
                      <th style={{textAlign:'center'}}>{mymenus.end_date} </th>
                      <th style={{textAlign:'right'}}>{mymenus.time}</th>
                      </tr>
           <tr>
                <td style={{textAlign:'left'}}>  {item.start_date}</td>
                <td style={{textAlign:'center'}}> {item.end_date}</td>
                <td style={{textAlign:'right'}}>  {item.time}</td>
                          </tr>      
                        
</>

                  ) : (
                    ""
                  )}
                  </table>    
                 {item.submitted_as && item.submitted_as !== "" ? (
                    <div style={{color:'white',cursor:'pointer', backgroundColor:'#107824' ,textAlign:'center',border:'1px solid black',borderRadius:'50px',width:'50%',alignSelf:'center',padding:'5px'}} onClick={()=>navigate(
                      "/" +
                        mylanguage +
                        "/" +
                        mycountry +
                        "/" +
                        who_i_am +
                        "/" +
                        urlArray[3]+'/?cat='+cat+'&&title='+item.title_en+'&&id='+item.id+'&&fid=123'
                    )}>{mymenus.completed}</div>
                  ) : item.started_as &&
                    item.started_as !== "" &&
                    item.submitted_as === "" ? (
                    <div onClick={()=>navigate(
                      "/" +
                        mylanguage +
                        "/" +
                        mycountry +
                        "/" +
                        who_i_am +
                        "/" +
                        urlArray[3]+'/?cat='+cat+'&&title='+item.title_en+'&&id='+item.id+'&&fid=123'
                    )} style={{alignSelf:'center',cursor:'pointer', backgroundColor:'#f4d160', width:'50%', border:'1px solid black', borderRadius:'50px',padding:'5px'}} className="text-center">{mymenus.incomplete}</div>
                  ) : (
                    <div onClick={()=>navigate(              "/" +
                    mylanguage +
                    "/" +
                    mycountry +
                    "/" +
                    who_i_am +
                    "/" +
                    urlArray[3]+'/?cat='+cat+'&&title='+item.title_en+'&&id='+item.id+'&&fid=321')} style={{alignSelf:'center',textAlign:'center',cursor:'pointer', backgroundColor:'#007694',color:'white', width:'50%', border:'1px solid black', borderRadius:'50px',padding:'5px'}}>{mymenus.not_started}</div>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </>
     :'Not Allowed Here. You Must Login to Come Here' )}
      <Row style={{marginTop:'3%'}}>
        <Col sm={{span:'6',offset:'3'}} style={{textAlign:'center'}}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <Button style={{backgroundColor:'#007694', borderColor:'white'}}
          onClick={() => setPage((old) => Math.max(old - 1, 0))}
          disabled={page === 0}
        >
          Previous Page
        </Button>{" "}
        <span>Current Page: {page + 1}</span>
        <Button style={{backgroundColor:'#007694',borderColor:'white'}}
          onClick={() => {
            if (!isPreviousData && data.hasMore) {
              setPage((old) => old + 1);
            }
          }}
          // Disable the Next Page button until we know a next page is available
          disabled={isPreviousData || !data?.hasMore}
        >
          Next Page
        </Button>
        </div>
        {isFetching ? <Spinner style={{color:'#007694'}}/> : null}{" "}
        </Col>
      </Row>
    </Container>
  );
}

export default Category_Wise;
