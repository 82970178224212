import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Container, Row, Col, Card, Form, Spinner } from 'react-bootstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {  app_menus, user_img } from './AtomDefined';

const Image_Upload = ({ closeModal }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 150, height: 150 });
  const [loading,setloading]  = useState(false);
  const setimage = useSetRecoilState(user_img);
  const mymenus = useRecoilValue(app_menus)

  const [zoom, setZoom] = useState(1);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

 
  const handleSaveImage = async () => {
    setloading(true);
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      const croppedImage = canvas.toDataURL();
      const blobPromise = new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        });
      });
  
      const blob = await blobPromise;
      const formData = new FormData();
      const url = 'https://bujho-quiz.com/server_files/profile_picture.php';
    formData.append('image', blob, 'croppedImage.png');
    fetch(url, {
        method: 'POST',
        credentials:'include',
        body: formData,
      }) 
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        setloading(false);
        if(data!==2)
        {
          setimage(data);
          closeModal();

        }
      })
      .catch((error) => {
       setloading(false);
      });
    }
  };

  const handleImageChange = () => {
    // Handle the image change if needed
  };

  const handlePositionChange = (position) => {
    setCrop({
      x: position.x,
      y: position.y,
      width: position.width,
      height: position.height
    });
  };

  const handleZoomChange = (event) => {
    setZoom(parseFloat(event.target.value));
  };

  return (
    <Container>
      <Row>
        <Col sm={12} className='text-center' style={{alignContent:'center',alignItems:'center'}}>
          
          <Form.Group  controlId="formFile" className="mb-3">
        
        <Form.Control   type="file" accept=".jpg, .jpeg, .png" placeholder={mymenus.nfc} onChange={handleImageUpload} />
      </Form.Group>
            
      
          {selectedImage && (
            <div style={{ borderColor: 'black', border: '1px solid black', width: '150px', height: '150px', borderRadius: '50%', overflow: 'hidden' }}>
              <AvatarEditor
              className='text-center'
                ref={(ref) => setEditor(ref)}
                image={selectedImage}
                width={150}
                height={150}
                border={0}
                color={[0, 0, 0]} // RGBA values
                scale={zoom}
                borderRadius={150}
                position={crop}
                onImageChange={handleImageChange}
                onPositionChange={handlePositionChange}
              />
            </div>
          )}



         {selectedImage && <> 
     
          <div className="mt-3">

            <input
              type="range"
              min="0.1"
              max="2"
              step="0.1"
              value={zoom}
              onChange={handleZoomChange}
              style={{width:'100%'}}
            /> 
         {loading? <Spinner/>  :  <Button onClick={handleSaveImage}>{mymenus.submit}</Button>   }
          </div> </>  }
        </Col>
      </Row>
  
    </Container>
  );
};

export default Image_Upload;
