import { Container, Row, Col } from "react-bootstrap"

function Help()
{
    return (
        <div>
            <Container>
                <Row>
                    <Col sm={{span:'6', offset:'3'}} className="content-box" style={{marginTop:'20px'}}>
                       <h6>Help</h6> 
                       <div>
                       Welcome to Bujho Medical Quiz, an app for all your medical knowledge needs! 
                       With Bujho Medical Quiz, you have the power to ask any App related question and even provide valuable suggestions by simply sending us an email at support@bujho-quiz.com. We value your input and are here to assist you in any way we can. Your feedback helps us improve and grow. While we hope you choose to stay with us, we understand that circumstances may change. If you ever decide to delete your account, don't worry – it's a straightforward process. Just send us an email at support@bujho-quiz.com, and we'll guide you through it. Thank you for choosing Bujho Medical Quiz as your trusted medical resource. We're here to serve you!






                       </div>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Help