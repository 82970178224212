import { Container, Row, Col, Button, Alert } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { app_menus, country, iam, language } from "./AtomDefined"
import { Navigate, useNavigate } from "react-router-dom";
import Download_Prompt from "./Download_Prompt";
import FooterMen from "./FooterMen";

function Footer()
{
    const mymenus = useRecoilValue(app_menus);
    const mycountry = useRecoilValue(country);
    const mylanguage = useRecoilValue(language)
    const who_i_am = useRecoilValue(iam);
    const navigate = useNavigate();
    return(
        <div>

            <Container fluid style={{backgroundColor:'rgb(0, 118, 148)'}}>
                <Row style={{marginTop:'30px'}}>
                    <Col  md={{ span: 6, offset: 3 }}>
                        <div style={{margin:'20px'}}className="text-center"> <Button style={{color:'white'}} variant="ouline" onClick={()=>navigate(mylanguage+'/'+mycountry+'/'+who_i_am+'/terms_conditions')}>{mymenus.terms}</Button>  <Button style={{color:'white'}} variant="ouline" onClick={()=>navigate(mylanguage+'/'+mycountry+'/'+who_i_am+'/privacy_policy')}>{mymenus.privacy}</Button> <Button variant="ouline" style={{color:'white'}}>{mymenus.developed}</Button> </div>
                        <div style={{color:'white'}} className="text-center">© {mymenus.copyright} 2023, <span className="link">www.bujho-quiz.com</span> </div>
                    </Col>
                </Row>
            </Container>
                <div style={{position:'sticky',zIndex:'1',bottom:'0'}}>   <FooterMen/> </div>
            <Download_Prompt/>
      
        </div>
    )
}
export default Footer