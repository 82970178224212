import { useState } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { language, iam, country } from "../AtomDefined";
import secureLocalStorage from "react-secure-storage";
import mylogo from '../../logo192.png';

function InitialSetup() {
  const [show, setShow] = useState(true);
  const [countryError, setCountryError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [iamError, setIamError] = useState('');
  const [mylanguage, setlanguage] = useRecoilState(language);
  const [isiam, setiam] = useRecoilState(iam);
  const [mycountry, setcountry] = useRecoilState(country);

  const handleClose = () => setShow(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const selectedCountry = event.target.elements.country.value;
    const selectedLanguage = event.target.elements.language.value;
    const selectedIam = event.target.elements.iam.value;

    // Validation
    let countryErr = '';
    let languageErr = '';
    let iamErr = '';
let flag = false;
    if (!selectedCountry) {
      countryErr = 'Please select a country';
      flag=true;
    }

    if (!selectedLanguage) {
      languageErr = 'Please select a language';
      flag = true;
    }

    if (!selectedIam) {
      iamErr = 'Please select an option for "Want Access As"';
      flag=true;
    }

    // Update state variables with error messages
    setCountryError(countryErr);
    setLanguageError(languageErr);
    setIamError(iamErr);

    // Proceed with submission if no errors
    if (!countryErr && !languageErr && !iamErr) {
      secureLocalStorage.setItem("country",selectedCountry);
      secureLocalStorage.setItem("iam",selectedIam);
    secureLocalStorage.setItem("language",selectedLanguage);
    setlanguage(selectedLanguage);
    setcountry(selectedCountry);
    setiam(selectedIam);
    handleClose();
          
    }
  };

  return (
    <>{console.log(mylanguage)}
    {mylanguage===''?
    <>  <Container style={{background:'#007694'}} fluid>
      <Col className="text-center"><img src={mylogo} height={'100'}/></Col> 
      </Container>
    <Container><Row><Col md={{span:6,offset:3}}>
        <Form style={{padding:'2em',marginTop:'1em', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} onSubmit={handleSubmit}>
          <Form.Group controlId="country" className="form-group">
            <Form.Label>Country</Form.Label>
            <Form.Control
              as="select"
              isInvalid={countryError !== '' ? true : false}
              onChange={()=>setCountryError('')}
            >
              <option value="">Select Country</option>
              <option value="In">India</option>
            </Form.Control>
            <span className="error-text">{countryError}</span>
          </Form.Group>

          <Form.Group controlId="language" className="form-group">
            <Form.Label>Language:</Form.Label>
            <Form.Control
              as="select"
              isInvalid={languageError !== '' ? true : false}
              onChange={()=>setLanguageError('')}
            >
              <option value="">Select Language</option>
              <option value="en">English</option>
              <option value="hi">Hindi</option>
            </Form.Control>
            <span className="error-text">{languageError}</span>
          </Form.Group>

          <Form.Group controlId="iam" className="form-group">
            <Form.Label>Want Access As:</Form.Label>
            <Form.Control
              as="select"
              isInvalid={iamError !== '' ? true : false}
              onChange={()=>setIamError('')}
            >
              <option value="">What Are You</option>
              <option value="dr">Doctor</option>
              <option value="oth">Other</option>
            </Form.Control>
            <span className="error-text">{iamError}</span>
          </Form.Group>

          <Button style={{background: '#007694', border:'1px solid white'}}  className="mt-3"  type="submit">
            Submit
          </Button>
        </Form></Col></Row></Container></>:''} </>
   
  );
}

export default InitialSetup;
