import secureLocalStorage from "react-secure-storage";
import { atom } from "recoil";
let cn='';
let lan = '';
if(secureLocalStorage.getItem('language'))
{
  lan = secureLocalStorage.getItem('language');
}
if(secureLocalStorage.getItem('country'))
{

  cn = secureLocalStorage.getItem('country');

}

const country = atom({
  key: 'country', // unique ID (with respect to other atoms/selectors)
  default: 'In', // default value (aka initial value)
});
const language = atom({
    key: 'language', // unique ID (with respect to other atoms/selectors)
    default: 'en', // default value (aka initial value)
  });
  const iam = atom({
    key: 'iam', // unique ID (with respect to other atoms/selectors)
    default: 'dr', // default value (aka initial value)
  });
  const name = atom({
    key: 'name', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });

  const app_menus = atom({
    key: 'app_menus', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
  });
 
  const user_img = atom({
    key: 'user_img', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });
  const last_url = atom({
    key: 'last_url', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });




  export {user_img,country,language, iam, app_menus,name,last_url};