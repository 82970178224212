import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { app_menus, country, iam, language } from "./AtomDefined";
function SetPassword(props)
{
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, SetpasswordError] = useState('');
    const [repassword, setRepassword] = useState('');
    const [repasswordError, setRrepasswordError] = useState('');
    const mycountry = useRecoilValue(country);
    const mylang = useRecoilValue(language);
    const mymenus = useRecoilValue(app_menus);
    const who_i_am = useRecoilValue(iam);
    const navigate = useNavigate();
    const myform = new FormData();
    myform.append('password','');
    myform.append('reset_password',props.email) ;
    const resetpassword = async () => {
      const url = 'https://bujho-quiz.com/server_files/setp.php';
      try {
        const response = await fetch(url, 
            {
                method:'POST',
                body:myform,
                credentials:'include',
             });
             const data = await response.json();
            if(data===1)
            {
                alert(mymenus.password_update);
                navigate('/'+mylang+'/'+mycountry+'/'+who_i_am+'/login');
            }
            if(data===2)
            {
                setRrepasswordError("You Are Trying to Attempt something Wrong !! If not please contact to Support");
            }
      }   
       catch (error) {
        
        alert('There is some Error in your Data, Please Try after some time');
      } finally {

      }
    };
   const handleFormSubmit  = (e) =>
    {
        e.preventDefault();
        let flag = true;
        if (password.trim() === '') {
            SetpasswordError(mymenus.password_error);
            flag = false;
            
          } else if (!/\d/.test(password)) {
            SetpasswordError(mymenus.password_error);
            flag = false;
          } else if (password.length < 8 || password.length > 20) {
            SetpasswordError(mymenus.password_error);
            flag = false;
          } else {
            SetpasswordError('');
          }

          if(repassword.trim()==='')
    {
      setRrepasswordError(mymenus.cpassp);
      flag = false;
    }
    else if (password!=repassword)
    {
      setRrepasswordError(mymenus.cpassword_error);
      flag = false;
    }
    if(flag===true)
    {
      myform.set('password',password);
      resetpassword();
    }

    }
    return(
        <div style={{ fontSize: "18px" }}>
            <Container className="align-items-center justify-content-center">
                <Row>
                <Col sm={8} className="content-box" style={{ padding: "5%" }}>
            <h2>{mymenus.fgpass}</h2>
            <Form onSubmit={handleFormSubmit}>
            <Form.Group className="form-group">
                <div style={{ display: "flex" }}>
                  <Form.Label style={{ alignItems: "flex-start" }}>
                    {mymenus.password}
                  </Form.Label>
                  <div
                    className="password-toggle"
                    style={{ alignItems: "flex-end", marginLeft: "70%" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeSlash /> : <Eye />}
                  </div>
                </div>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    SetpasswordError("");
                  }}
                  isInvalid={passwordError!==''?true:false}
                />
                        <span className="error-text">{passwordError}</span>
              </Form.Group>

              <Form.Group className="form-group">
                  <Form.Label >
                    {mymenus.cpassp}
                  </Form.Label>
                  
                <Form.Control
                  type= "password"
                  value={repassword}
                  onChange={(e) => {
                    setRepassword(e.target.value);
                    setRrepasswordError("");
                  }}
                  isInvalid={repasswordError!==''?true:false}
                />
                        <span className="error-text">{repasswordError}</span>
              </Form.Group>

              <Button type="submit" style={{marginTop:'10px',backgroundColor:'rgb(0, 118, 148)'}}>{mymenus.submit}</Button>
              </Form>

                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default SetPassword