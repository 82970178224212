import {  Container, Navbar, Row, Col, Button } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { app_menus, country, iam, language } from "./AtomDefined";
import { useLocation, useNavigate } from "react-router-dom";
import { CalendarPlus } from "react-bootstrap-icons";
function FooterMen() {
  const mycountry = useRecoilValue(country);
  const mymenus = useRecoilValue(app_menus)
  const mylang = useRecoilValue(language);
  const isiam = useRecoilValue(iam);
  const navigate = useNavigate();
  const mylocation = useLocation();
  const urlArray = mylocation.pathname.split("/").filter((part) => part !== "");
  return (
    <Container fluid>
      <Row >
      
          <Col>
            <Row style={{border:'1px solid black',color:'white'}}>
              <Col style={{textAlign:'center',cursor:'pointer',borderRight:'2px solid white',backgroundColor: !urlArray[3] || urlArray[3]==='dashboard' || urlArray[3]==='archive' ?'#968f3c': '#007694',padding:'5px'}}    onClick={() => {
                    navigate(`/${mylang}/${mycountry}/${isiam}/`);
                  }}>
         
                <CalendarPlus/>  {mymenus.today}
              
              </Col>
              <Col className="text-center" style={{borderRight:'2px solid white',cursor:'pointer',backgroundColor: urlArray[3]==='exercise'?'#968f3c': '#007694',padding:'5px'}}  onClick={() => {
                    navigate(`/${mylang}/${mycountry}/${isiam}/exercise`);
                  }}>
         
                   {mymenus.exercise}
                  
              </Col>
              <Col style={{textAlign:'center',cursor:'pointer',backgroundColor: urlArray[3]==='quiz'?'#968f3c': '#007694',padding:'5px'}}    onClick={() => {
                    navigate(`/${mylang}/${mycountry}/${isiam}/quiz`);
                  }}>
    
                   {mymenus.quiz}
                 
              
              </Col>
            </Row>
          </Col>
    
      </Row>
    </Container>
  );
}
export default FooterMen;
