const User_Pic = (props) => {
  const myname = props.name;
  const myimage = props.image;

  return (
    <div>
      <div className="circle">
        {myimage && myimage !== null ? (
          <>
            {" "}
            <div className="circle-content">
              {" "}
              <img
                src={`https://bujho-quiz.com/server_files/upload/${myimage}`}
                style={{ borderRadius: "50%" }}
                height={"80px"}
                width={"80px"}
              />
            </div>{" "}
          </>
        ) : (
          <div className="circle-content">
            {" "}
            <span className="circle-character">
              {" "}
              {myname.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default User_Pic;
