import { useState } from "react";
import { Container,Row, Col, Form, Button, Spinner } from "react-bootstrap";
import SetPassword from "./SetPassword";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { app_menus } from "./AtomDefined";
function Forgot_Password()
{
    const myform = new FormData;
    myform.append('checkEmail','');
    const otSubmit = new FormData;
    otSubmit.append('submit_otp','');
    otSubmit.append('email','');
    const [email, setEmail] = useState('');
    const [getotp, setGetotp] = useState(false);
    const [emailError, setEmailError]  = useState('');
    const [isloading,setloading] = useState(false);
    const [otp, setOtp] = useState('')
    const [otpsuccess, setotpsuccess] = useState(false);
    const mymenus = useRecoilValue(app_menus);
    const forgotpassword = async () => {
      setIsButtonDisabled(true);
      setRemainingTime(60);
      myform.set('checkEmail',email);
      const url = 'https://bujho-quiz.com/server_files/fg.php';
      setloading(true);
      try {
        const response = await fetch(url, 
            {
                method:"POST",
                body:myform,
                });

                const data = await response.json();
                if (data === 0) {
                    setEmailError(mymenus.email_not_found);
                  } else if (data === 1) {
                    setEmailError(mymenus.max_limit);
                  } else if (data === 2) {
                    setEmailError('');
                    setGetotp(true);
                  }
      }catch (error) {
       alert(error);
      } finally {
        setloading(false);
      }
    };


    const submitOTP = async () => {
      const url = 'https://bujho-quiz.com/server_files/setp.php';
      try {
        
        const response = await fetch(url,
            {
                method:'POST',
                body: otSubmit, 
               });
               const data = await response.json();
             if(data===1) {
              setotpsuccess(true);
            }
            if(data===2)
            {
              setEmailError(mymenus.max_limit);
            }
            if(data===3)
            {
             
              setEmailError(mymenus.otp_error);
            }
          
 
      }   
       catch (error) {
        
        alert('There is some Error in your Data, Please Try after some time');
      } finally {

      }
    };

    const [remainingTime, setRemainingTime] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
    useEffect(() => {
      let interval;
      
      if (remainingTime > 0 && isButtonDisabled) {
        interval = setInterval(() => {
          setRemainingTime(prevTime => prevTime - 1);
        }, 1000);
      } else {
        setIsButtonDisabled(false);
      }
  
      return () => clearInterval(interval);
    }, [remainingTime, isButtonDisabled]);
  

  
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };


  const  handleFormSubmit = (e) =>
    {
        e.preventDefault();
        let flag = true;
        if(email.trim()==='')
        {
            setEmailError(mymenus.email_error);
            flag = false;
        }
        if(flag===true)
        {
         
          forgotpassword();
        }
    }
    const  handleOTPSubmit = (e) =>
    {
        e.preventDefault();
        let flag = true;
        if(otp.trim()==='')
        {
            setEmailError(mymenus.otp);
            flag = false;
        } else if(otp.length!=4)
        {
          setEmailError(mymenus.otp);
          flag = false;

        }
        if(flag===true)
        {
          otSubmit.set('submit_otp',otp);
          otSubmit.set('email',email);
          submitOTP();
          
        }
    }
    return(
        <div style={{ fontSize: "18px" }}>{(otpsuccess===false)?
            <Container className="align-items-center justify-content-center">
        <Row>
          <Col sm={8} className="content-box" style={{ padding: "5%" }}>
            <h2>{mymenus.fgpass}</h2>
            
          {(getotp===false) ?
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="name" className="form-group">
                <Form.Label>{mymenus.email}</Form.Label>
                <Form.Control
                    placeholder={mymenus.email}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                     setEmailError('');   
                   
                  }}
                  isInvalid = {emailError!==''?true:false}
                />
                <span className="error-text">{emailError}</span>
              </Form.Group>
             {isloading?<Spinner color="07617a"/>: <Button type="submit" style={{marginTop:'10px',background: '#007694', border:'1px solid white'}}>{mymenus.submit}</Button>}
              </Form>:<Form onSubmit={handleOTPSubmit}>
              <Form.Group controlId="name" className="form-group">
                <Form.Label>{mymenus.otp_sent}</Form.Label>
                <Form.Label style={{color:'red'}}>{mymenus.otp_msg}</Form.Label>

                <Form.Control
                    placeholder={mymenus.otp}
                  type="number"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                     setEmailError('')   
                   
                  }}
                  isInvalid = {emailError!==''?true:false}
                />
                <span className="error-text">{emailError}</span>
              </Form.Group>
              {!isloading?<Spinner color="07617a" />:<Button type="submit" style={{marginTop:'10px',background: '#007694', border:'1px solid white'}}>{mymenus.submit}</Button> }
              <Button
        type="button"
        style={{ marginLeft:'5%', marginTop: '10px', background: '#007694', border:'1px solid white' }}
        onClick={() => {
          setOtp('');
          forgotpassword();
        }}
        disabled={isButtonDisabled}
      >

        {isButtonDisabled ? mymenus.otp_w + ' ' + formatTime(remainingTime) :  mymenus.orsnd }
      </Button>
              </Form>}
                 </Col>
                 </Row></Container> :<SetPassword email={email}></SetPassword>}
        </div>
    )
}
export default Forgot_Password